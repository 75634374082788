<template>
  <aside class="menu sidebar" id="sidebar-main" ref="menus" v-click-out="clickOutSideBar">
    <ul class="menu-list" id="menu-list-sidebar">
      <li class="is-single time-week" v-if="!is_tablet">
          <div>
            <span class="text-time fw__bold">{{$t('sidebar.time')}}</span>
          </div>
          <div>
            <span class="time fw__bold">{{ sysTemDate }}</span>
          </div>
      </li>
      <template v-for="(menu, index) in menus">
        <li class="is-single"
            :key="'menu-' + index"
            v-if="menu.isShown"
            :class="{'is-active': menu.activeName === activeSidebar}">
          <a @click="goTo(menu.router, menu.currentData, menu.activeName)"><img
              :src="menu.activeName === activeSidebar ? menu.path_svg : menu.path_svg_dark"><span class="input-title fs-14-12">{{ menu.title }}</span></a>
        </li>
      </template>
    </ul>
  </aside>

</template>

<script>
import {mapGetters} from 'vuex'
import Sidebar from '@/mixins/sidebar'
import {ACTION_CLEAR_SEARCH_PARAMS} from "@/stores/common/actions";
import {weekDay} from '@/filters'
// import {ACTION_SET_LOADING, ACTION_FINISH_LOADING} from "@/stores/common/actions";

export default {
  name: "sidebar",
  mixins: [Sidebar],
  data() {
    return {
      is_tablet: false
    }
  },
  computed: {
    ...mapGetters({
      activeSidebar: 'activeSidebar'
    }),
    sysTemDate() {
      return weekDay()
    },
  },
  props: {
    flagOutside: {
      default: true
    }
  },
  watch: {

  },
  methods: {
    clickOutSideBar(){
      if(this.flagOutside) {
        this.$emit('click-out')
      }
    },
    goTo(link, current, active) {
      this.$store.dispatch(ACTION_CLEAR_SEARCH_PARAMS, '')
      localStorage.removeItem('searchParams')
      localStorage.setItem('prev', active)
      this.$emit('click-out')
      if (this.$route.name === link.name) {
        this.$emit('refresh')
      } else {
        this.$router.push(link, () => {
        })
      }
    },
    myEventHandler() {
      if (window.innerWidth <= 1366) {
        this.is_tablet = true
      } else {
        this.is_tablet = false
      }
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    if (window.innerWidth <= 1366) {
      this.is_tablet = true
    } else {
      this.is_tablet = false
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  }
}
</script>

<style lang="scss" scoped>
  .sidebar {
    top: 60px !important;
    left: 97px;
    width: 250px;
    background: #E3E3E3 !important;
    padding-top: 20px;
  }
  .is-single {
    margin: 0 10px 10px 0;
    border-radius: 10px;
    a:hover span {
      color: $blue_main !important;
    }
  }
  .time-week {
    height: 50px;
    padding: 9px 10px;
    background: #FFFFFF;
    div {
      .text-time {
        font-size: 14px;
        color: #8E9DA3;
      }
      .time {
        font-size: 10px;
      }
    }
  }
  @media screen and (max-width: 1366px){
    .sidebar {
      top: 60px !important;
      left: 0px;
      width: 165px;
    }
    .sidebar .menu-list li span {
      font-size: 10px;
    }
    .menu-list li a img {
      width: 20px;
      height: 20px;
      margin-right: 10px !important;
    }
    .is-single {
      border-radius: 0 10px 10px 0;
    }
    .sidebar .menu-list li a {
      padding: 8px 0px 8px 10px !important;
    }
    .menu-list a:hover {
      background-color: unset !important;
    }

    .is-single {
      a:hover span {
        color: #8A9CA3 !important;
      }
    }
  }
</style>