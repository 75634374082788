<template>
  <section class="is-primary layout-content">
    <header-menu/>
    <div class="content-main" style="padding-top: 20px; padding-bottom: 80px">
      <sidebar @refresh="refresh" @click-out="clickOutSidebar" :flagOutside="check"></sidebar>
      <div class="home-page-main">
        <router-view v-if="isRouterAlive"></router-view>
      </div>
    </div>
  </section>
</template>

<script>
    import sidebar from "./sidebar";
    import headerMenu from "./headerMenu";
    export default {
        name: "layout-app",
        components: {
            sidebar,
            headerMenu
        },
        data() {
            return {
                check: true,
                isRouterAlive: true
            }
        },
        created() {
            this.$nextTick(() => {
                this.toggleNavBar()
                this.toggleSideBar()
            })
        },
      methods: {
          refresh() {
              this.isRouterAlive = false
              setTimeout(()=>{
                  this.isRouterAlive = true
              })
          },
          clickOutSidebar() {
              const $sideBurgers = Array.prototype.slice.call(this.$el.querySelectorAll('.sidebar-burger'), 0);
              const navbar = this.$el.querySelectorAll('.navbar')[0];
              if ($sideBurgers.length > 0) {
                  $sideBurgers.forEach( el => {
                      const target = el.dataset.target;
                      const $target = this.$el.querySelector(`#${target}`);
                      if($target.classList.contains('is-active') ) {
                          el.classList.remove('is-active');
                          $target.classList.remove('is-active');
                          navbar.style.zIndex = '40';
                          navbar.classList.remove('has-sidebar');
                      }
                  });
              }
          },
        toggleSideBar(clickOut = false) {
          const $sideBurgers = Array.prototype.slice.call(this.$el.querySelectorAll('.sidebar-burger'), 0);
          const navbar = this.$el.querySelectorAll('.navbar')[0];
          // const container = this.$el.querySelector('#page-container');
          if ($sideBurgers.length > 0) {
            $sideBurgers.forEach( el => {
              el.addEventListener('click', () => {
                const target = el.dataset.target;
                const $target = this.$el.querySelector(`#${target}`);
                if(clickOut) {
                  el.classList.remove('is-active');
                  $target.classList.remove('is-active');
                } else {
                  el.classList.toggle('is-active');
                  $target.classList.toggle('is-active');
                }
                if($target.classList.contains('is-active')) {
                  // container.classList.add('has-sidebar');
                  navbar.style.zIndex = '39';
                  navbar.classList.add('has-sidebar');
                  this.check = false
                    setTimeout(() => {
                        this.check = true
                    })
                } else {
                  // container.classList.remove('has-sidebar');
                  this.check = true
                  navbar.style.zIndex = '40';
                  navbar.classList.remove('has-sidebar');
                }
              });
            });
          }
        },
        toggleNavBar() {
          const $navbarBurgers = Array.prototype.slice.call(this.$el.querySelectorAll('.navbar-burger'), 0);
          if ($navbarBurgers.length > 0) {
            $navbarBurgers.forEach( el => {
              el.addEventListener('click', () => {
                const target = el.dataset.target;
                const $target = this.$el.querySelector(`#${target}`);
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
              });
            });
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
  .home-page-main {
    margin-top: 60px;
    margin-left: 257px;
    //background: #FFFFFF;
    border-radius: 10px;
  }
  .bg_none {
    background-color: unset !important;
  }
  .layout-content {
    background: #E3E3E3;
  }
  @media screen and (max-width: 1366px){
    .home-page-main {
      margin-left: 150px;
    }
  }
</style>