export default {
  data() {
    return {
      menus: [
        {
          path_svg: require('@/assets/svgs/SideBar/ic_search_1_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/ic_search_1.svg'),
          title: this.$t('sidebar.search'),
          router: {name: 'SearchDocuments', path: 'search'},
          permissions: '',
          children: [],
          activeName: 'search',
          currentData: 'documents',
        },
        {
          path_svg: require('@/assets/svgs/SideBar/ic_add_file_3_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/ic_add_file_3.svg'),
          title: this.$t('sidebar.create_document'),
          router: {name: 'CreateDocument', path: '/document/create'},
          permissions: '',
          children: [],
          activeName: 'documents'
        },
        {
          path_svg: require('@/assets/svgs/SideBar/ic_File_1_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/ic_File_1.svg'),
          title: this.$t('sidebar.files_list'),
          router: {name: 'Folders', path: 'folders'},
          permissions: '',
          children: [],
          activeName: 'folders',
          currentData: 'folders',
        },
        {
          path_svg: require('@/assets/svgs/SideBar/ic_search_user_1_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/ic_search_user_1.svg'),
          title: this.$t('sidebar.user_management'),
          router: {name: 'Users', path: 'users'},
          permissions: '',
          children: [],
          activeName: 'users',
          currentData: 'users',
        },
        {
          path_svg: require('@/assets/svgs/SideBar/ic_file_setting_1_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/ic_file_setting_1.svg'),
          title: this.$t('sidebar.template_settings'),
          router: {name: 'MailTemplate', path: 'mail-templates'},
          permissions: '',
          children: [],
          activeName: 'mail-templates',
          currentData: 'mailTemplates',
        },
        {
          path_svg: require('@/assets/svgs/SideBar/ic_upload_File_1_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/ic_upload_File_1.svg'),
          title: this.$t('sidebar.email_capture'),
          router: {name: 'ImportMailAddress', path: 'import'},
          permissions: '',
          children: [],
          activeName: 'import-mail-address',
          currentData: 'importMailAddress',
        },
        {
          path_svg: require('@/assets/svgs/SideBar/Asset_active.svg'),
          path_svg_dark: require('@/assets/svgs/SideBar/Asset.svg'),
          title: this.$t('sidebar.page_setting'),
          router: {name: 'PageSetting', path: 'alert-general-setting'},
          permissions: '',
          children: [],
          activeName: 'page-setting',
          currentData: 'pageSetting',
        },
      ],
      menuShow: {
        search: true,
        documents: true,
        folders: true,
        users: false,
        'mail-templates': false,
        'import-mail-address': false,
        'user-service': false,
        'page-setting': false,
      }
    }
  },
  watch: {
    userInfo(val) {
      this.setSidebarRoles(val)
    }
  },
  methods: {
    setSidebarRoles(user) {
      if (user) {
        this.menuShow = {
          search: true,
          documents: true,
          folders: true,
          users: user.role_id === 1 || user.role_id === 2,
          'mail-templates': user.role_id === 1 || user.role_id === 2,
          'import-mail-address': user.role_id === 1 || user.role_id === 2,
          'user-service': user.role_id === 1 || user.role_id === 2,
          'page-setting': user.role_id === 1 || user.role_id === 2,
        }
        this.menus.map((e) => {
          this.$set(e, 'isShown', this.menuShow[e.activeName])
        })
      }
    }
  },
  created() {
    this.setSidebarRoles(this.userInfo)
  },
  mounted() {
    this.setSidebarRoles(this.userInfo)
  }
}