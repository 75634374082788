<template>
  <nav class="navbar content-main-menu nav-menu-main" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <span class="navbar-item nav-logo" @click="$router.push({name: 'home'}, ()=>{})">
        <img src="@/assets/svgs/Logo/logoDoc.svg" width="147px" height="35px">
      </span>
    </div>
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item nav-user-item">
          <div class="nav-user">
            <div class="nav-user-name">
              <div class="dropdown is-right" v-click-out="() => clickOut('isActiveDesktop')"
                   :class="{'is-active' : isActiveDesktop}"
                   @click="isActiveDesktop = !isActiveDesktop">
                <div class="dropdown-trigger">
                  <span aria-haspopup="true" aria-controls="dropdown-submenu"
                        class="dropdown-submenu d-flex align-items-center">
                    <span class="profile-name">{{
                        userInfo && (userInfo.profile && userInfo.profile.name || userInfo.name)
                      }}</span>
                    <span class="icon is-small position-unset">
                     <img src="../../assets/svgs/ic_drop.svg" alt="">
                    </span>
                  </span>
                </div>
                <div class="dropdown-menu" id="dropdown-submenu" role="menu">
                  <div class="dropdown-content">
                    <a @click="logout()" class="dropdown-item">
                      <i class="fas fa-sign-out-alt m__right--5"></i>
                      {{ $t('navbar.buttons.logout') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-user-avatar" @click="profile()">
              <img class="avatar-user" :src="avatar">
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {ACTION_LOGOUT} from "@/stores/auth/actions";
export default {
  name: "headerMenu",
  data() {
    return {
      isActiveDesktop: false
    }
  },
  computed: {
    avatar() {
      if (this.userInfo && this.userInfo.profile && this.userInfo.profile.avatar) {
        return this.userInfo.profile.avatar
      }
      return require('../../assets/svgs/ic_avatar.svg')
    }
  },
  methods: {
    clickOut(active) {
      this[active] = false
    },
    profile() {
      this.$router.push({name: "Profile"}, () => {
      });
    },
    async logout() {
      await this.$store.dispatch(ACTION_LOGOUT)
          .then(() => {
            localStorage.removeItem('searchParams')
            this.$router.push({name: "login"}, () => {
            });
          })
          .catch((e) => console.log(e));

    },
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background: #006FC8;
  max-height: 60px !important;
  min-height: 60px !important;
}
.navbar-item img {
  max-height: 35px !important;
}
.nav-user {
  margin-right: 0px;
}
.nav-user-item {
  padding-right: 0px;
}
.nav-menu-main {
  position: fixed;
  width: 100%;
  height: 60px !important;
}
.nav-logo {
  padding-left: 0px;
}
.profile-name {
  color: #64C7FF;
  font-size: 14px;
}
.avatar-user {
  width: 30px !important;
  height: 30px !important;
}
@media screen and (max-width: 1023px){
  .navbar {
    background: #006FC3 !important;
  }
  .navbar-menu {
    display: block;
    background: #006FC3 ;
    box-shadow: none;
  }
  .nav-menu-main {
    display: flex;
    justify-content: space-between;
  }
}
</style>